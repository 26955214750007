<template>
  <c-box style="position: relative" bg="vc-white.20">
    <c-flex
      class="header"
      align="['start','start', 'space-between']"
      flexDirection="'column', 'column', 'row'"
      justify="space-between"
      mt="4"
      mb="4"
    >
      <c-flex
        pt="10px"
        justify="['space-between','start', 'space-between']"
        align="center"
      >
        <c-heading
          pt="2"
          fontSize="2xl"
          fontWeight="700"
          mr="3"
          ml="3"
          :mb="['2', '2', '0']"
        >
          Events
        </c-heading>
      </c-flex>
      <!--      <c-flex style="margin-right: -280px; margin-top: 50px" justify="end">-->
      <!--        <c-icon-button-->
      <!--          :disabled="eventsOffset === 0"-->
      <!--          @click="moveBack"-->
      <!--          ariaLabel="next button"-->
      <!--          icon="chevron-left"-->
      <!--        />-->
      <!--        <c-icon-button-->
      <!--          :disabled="eventsOffset >= eventsTotal - eventsLimit"-->
      <!--          @click="moveForward"-->
      <!--          ariaLabel="next button"-->
      <!--          icon="chevron-right"-->
      <!--        />-->
      <!--      </c-flex>-->
      <c-flex align="center" justify="['start','end', 'space-between']">
        <search @hide-sidebar="handleHideSidebar" />
        <c-link
          ml="20px"
          mt="15px"
          class="saved"
          @click="$router.push('/app/messaging')"
        >
          <svg class="svgmessage">
            <use href="@/assets/icons/comment-fill.svg#comment" />
          </svg>
          <span class="custom_badge">{{ unreadMessageCount }}</span>
          <!-- <c-icon size="6" color="vc-orange.300" name="message" /> -->
        </c-link>
        <c-box class="notification" mt="15px" ml="8">
          <Notification />
        </c-box>
      </c-flex>
    </c-flex>
    <c-flex :flexDirection="['column', 'column', 'row']">
      <c-flex h="100%" :flex="hideSidebar ? '1' : '3'">
        <div style="width: 100%">
          <c-flex
            w="800px"
            h="500px"
            v-if="isFetchingEvents"
            justify="center"
            align="center"
          >
            <c-spinner size="xl" color="orange.500" thickness="2px"></c-spinner>
          </c-flex>
          <div v-else>
            <div
              :class="{
                past_event: isPastEvent(event.end_date),
                event_div: isPastEvent(event.end_date),
              }"
              v-for="event in events"
              :key="event.id"
            >
              <div
                class="event"
                style="position: relative"
                @click="
                  $router.push({
                    name: 'EventDetails',
                    params: { id: event.id },
                  })
                "
              >
                <c-flex
                  style="position: absolute; right: -2px; top: -20px"
                  v-if="isPastEvent(event.end_date)"
                  align="center"
                  mt="2"
                >
                  <c-icon size="12px" name="info" />
                  <c-text ml="2" fontSize="11px">Event ended</c-text>
                </c-flex>
                <c-image
                  h="140px"
                  w="220px"
                  :src="
                    event.thumb_nail ??
                    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRFo4PR9dPR7LmV1CqeEB6F5Z3jikCg8xBBA&usqp=CAU'
                  "
                  alt="event thumbnail"
                />

                <div class="event_info">
                  <h2 class="event_title">{{ event.name }}</h2>
                  <small class="date">
                    {{ event.start_date }}, {{ event.start_time
                    }}{{ event.time_zone }}
                  </small>
                  <c-flex>
                    <c-flex justify="start" align="center">
                      <c-icon
                        v-if="event.type === 'VIRTUAL'"
                        size="14px"
                        name="video"
                      />
                      <c-icon v-else size="14px" name="users" />
                      <c-text ml="2" fontSize="14px" fontWeight="600">{{
                        event.type === 'VIRTUAL' ? 'Online' : 'In person'
                      }}</c-text>
                    </c-flex>
                    {{ event.company }}
                  </c-flex>
                  <p class="descr">
                    {{ event.description }}
                  </p>
                  <c-flex justify="start" align="center">
                    <c-icon name="user-friends" />
                    <p class="company ml-3">
                      {{ event.attendees_aggregate.aggregate.count }}
                      {{
                        event.attendees_aggregate.aggregate.count === 1
                          ? 'Attendee'
                          : 'Attendees'
                      }}
                    </p>
                  </c-flex>

                  <!--                <c-button size="sm" variant-color="vc-orange">-->
                  <!--                  Attend Event-->
                  <!--                </c-button>-->
                </div>
              </div>
              <!--            <div class="options">-->
              <!--              <div><i class="far fa-heart"></i> <span>like</span></div>-->
              <!--              <div>-->
              <!--                <i class="far fa-comment-dots"></i> <span>Comment</span>-->
              <!--              </div>-->
              <!--              <div><i class="fas fa-share"></i> <span>Share</span></div>-->
              <!--            </div>-->
              <!--            <div class="options">-->
              <!--              <div><i class="far fa-heart"></i> <span>like</span></div>-->
              <!--              <div>-->
              <!--                <i class="far fa-comment-dots"></i> <span>Comment</span>-->
              <!--              </div>-->
              <!--              <div><i class="fas fa-share"></i> <span>Share</span></div>-->
              <!--            </div>-->
            </div>
          </div>
          <infinite-loading @infinite="getMoreEvents">
            <span slot="no-more">
              <div style="color: rgba(255, 69, 0, 0.45)">No more events</div>
            </span>
            <span slot="no-results">
              <div style="color: rgba(255, 69, 0, 0.45)">No events yet</div>
            </span>
          </infinite-loading>
        </div>
      </c-flex>
      <c-flex v-if="!hideSidebar" ml="5" :flex="hideSidebar ? '0' : '3'">
        <sidebar />
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import { mapState } from 'vuex';
import Notification from '@/views/App/components/NotificationDialog.vue';
import Search from '@/views/App/Explore/Search.vue';
import Sidebar from '@/views/App/Explore/Sidebar.vue';
import { getEvents } from '@/services/event';
import cloneDeep from 'lodash.clonedeep';
import InfiniteLoading from 'vue-infinite-loading';
import moment from 'moment';

export default {
  name: 'Layout',
  components: {
    Search,
    Notification,
    Sidebar,
    InfiniteLoading,
  },
  data() {
    return {
      hideSidebar: false,
      isFetchingEvents: false,
      events: [],
      eventsLimit: 6,
      eventsOffset: 0,
      eventsTotal: 0,
      event_start: 'asc',
      event_end: 'asc',
    };
  },
  computed: {
    ...mapState('messaging', {
      unreadMessageCount: (state) => state.unreadMessageCount,
    }),
  },
  methods: {
    isPastEvent(endDate) {
      console.log('ingineeee', endDate);
      return moment(endDate).isBefore(moment());
    },
    moveForward() {
      if (this.eventsOffset < this.eventsTotal) {
        this.eventsOffset += 6;
        this.getEvents();
      } else {
        this.getEvents();
      }
    },
    moveBack() {
      if (this.eventsOffset > 0) {
        this.eventsOffset -= 6;
        this.getEvents();
      } else {
        this.getEvents();
      }
    },
    moveToSavedPage() {
      this.$router.push('/saved');
    },
    handleHideSidebar(status) {
      this.hideSidebar = status;
    },
    getEvents() {
      this.isFetchingEvents = true;
      getEvents({
        limit: this.eventsLimit,
        offset: this.eventsOffset,
      }).then((res) => {
        this.events = res.data.event;
        this.eventsTotal = res.data.event_aggregate.aggregate.count;
        this.isFetchingEvents = false;
      });
    },

    getMoreEvents($state) {
      this.eventsOffset = this.eventsOffset + this.eventsLimit;
      getEvents({
        limit: this.eventsLimit,
        offset: this.eventsOffset,
      }).then((res) => {
        this.events = [...cloneDeep(this.events), ...res.data.event];
        $state.loaded();
        if (this.eventsTotal === this.events.length) {
          $state.complete();
        }
      });
    },
  },

  created() {
    this.getEvents();
  },
  // watch: {
  //   $route(to) {
  //     // Perform actions here
  //     if (to.path === '/app/explore') {
  //       this.handleHideSidebar(false);
  //     }
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f9fbfd;
  padding-bottom: 20px;
  z-index: 100;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  // .saved {
  //   position: absolute;
  //   right: 50px;
  // }

  .menu {
    position: absolute;
    right: 10px;
    margin-top: 8px;
  }

  .notification {
    position: absolute;
    right: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .saved {
    right: 0;
  }

  .menu {
    display: none;
  }

  .header {
    position: sticky;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .menu {
    display: none;
  }
  .saved {
    top: 20px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .menu {
    display: none;
  }
  .saved {
    top: 20px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .menu {
    display: none;
  }
}

.event_div {
  padding: 20px;
  border: 1px solid #dae7ec;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 10px;
  height: 180px;
  margin-bottom: 20px;

  .event {
    display: flex;
    width: 100%;
    justify-content: space-between;
    // responsive image
    img {
      border-radius: 10px;
    }

    .event_title {
      max-height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* Number of lines to show */
      -webkit-box-orient: vertical;
    }

    .event_info {
      margin-left: 20px;
      flex: 1;

      h2 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;
      }

      .descr {
        max-height: 45px;
        width: 100%;
        font-size: 15px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Number of lines to show */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      .company {
        color: #6b7a84;
      }

      .date {
        max-height: 20px;
        color: #6b7a84;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1; /* Number of lines to show */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      .company {
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }

  .options {
    display: flex;
    margin-top: 20px;
    border-radius: 5px;
    background: rgb(136 162 216 / 14%);
    padding: 10px 20px;

    div {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 40px;

      svg {
        margin-right: 10px;
        color: var(--color-secondary);
      }
    }
  }
}

.past_event {
  padding: 20px;
  border: 1px solid #dae7ec;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: rgb(234 240 253);
  border-radius: 10px;
  height: 180px;
  margin-bottom: 20px;

  .event {
    display: flex;
    width: 100%;
    justify-content: space-between;
    // responsive image
    img {
      border-radius: 10px;
    }

    .event_title {
      max-height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* Number of lines to show */
      -webkit-box-orient: vertical;
    }

    .event_info {
      margin-left: 20px;
      flex: 1;

      h2 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0;
      }

      .descr {
        max-height: 45px;
        width: 100%;
        font-size: 13px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Number of lines to show */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      .company {
        color: #6b7a84;
      }

      .date {
        max-height: 20px;
        color: #6b7a84;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1; /* Number of lines to show */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      .company {
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }

  .options {
    display: flex;
    margin-top: 20px;
    border-radius: 5px;
    background: rgb(136 162 216 / 14%);
    padding: 10px 20px;

    div {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 40px;

      svg {
        margin-right: 10px;
        color: var(--color-secondary);
      }
    }
  }
}

.vue__time-picker input.display-time {
  border: 0;
  background-color: #c7d6f466;
  border-radius: 5px;
  height: 40px;
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns with equal width */
  grid-gap: 20px; /* Adjust the gap between grid items as needed */
}

.grid-item {
  width: 100%; /* Ensure grid items take up full width of their grid cell */
}

.form-labels {
  font-weight: 500;
  color: #002b49;
}
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: orangered;
}
svg.svgmessage {
  width: 23px;
  height: 23px;
  fill: #6b7a84;
}

.custom_badge {
  position: absolute;
  top: 18px;
  right: 45px;
  background: #f00;
  color: #fff !important;
  border-radius: 50%;
  width: 18px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
}
</style>
